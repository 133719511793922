// Copyright 2019 enzoames Inc. All Rights Reserved.

export default {
    nyc: [
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_0276-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_1353-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_2766-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_4310-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_5379-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_6267-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_7029-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_7538-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_9030-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_0321-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_1356-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_2786-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_5072-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_5890-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_6638-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_7053-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_8763-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_9457-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_0485-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_1544-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_4262-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_5150-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_6128-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_6643-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_7127-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_8894-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_9539-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_0775-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_1628-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_4267-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_5264-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_6194-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_6790-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_7153-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/nyc/ENZ_8949-1.jpg'
    ],
    world: [
        'https://s3.amazonaws.com/enzoames.com/photos/world/ENZ_8291.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/world/ENZ_8318.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/world/ENZ_8418.jpg'
    ],
    people: [
        // 'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_0816-1.jpg',
        // 'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_2238-1.JPG',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_2569-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3378-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3590-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_4053-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_5411-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_6609-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_7180-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_9681-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_1037-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_2546-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3098-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3503-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3674-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_4316-4.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_5475-5.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_6613-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_7558-1.jpg',
        // 'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_9792-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_1852-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_2554-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3194-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3520-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_3959-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_4337-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_6471-1.jpg',
        // 'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_6749-1.jpg',
        'https://s3.amazonaws.com/enzoames.com/photos/people/ENZ_7715-1.jpg'
    ]
};
