// Copyright 2019 enzoames Inc. All Rights Reserved.

export const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  GREY1: '#fafafa',
  GREY2: '#eaeaea',
  GREY3: '#555555',
  BLUE1: '#3b5998'
};
